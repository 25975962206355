* {
  scroll-behavior: smooth;
}
body {
  font-family: 'Open Sans', sans-serif;
  /* line-height: 18px; */
  color: #353535;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

/* header */
.a-logo {
  font-size: 28px;
  line-height: 32px;
  text-decoration: none;
  color: #757575;
}
.a-logo {
  background-image: url("images/clarktreelogo.jpg");
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 55px;
}
div.description {
  line-height: 29px;
  clear: right;
  float: none;
  font-style: italic;
}
.header-wrapper {
  border-bottom: 1px solid #ccc;
  padding: 12px 0;
  margin-bottom: 10px;
}
.content-wrapper h2.content-title { 
  margin: 0 0 20px 0; 
  padding-bottom: 10px; 
  border-bottom: 1px solid #ccc;
}
.our-services img {
  padding: 0 20px;
}

/* reviews page */
body .a-reviews-table td {
  border: 2px solid #e7e7e7;
  padding: 6px 24px;
}
body .a-reviews-table {
  color: black; 
  background-color: white;
  max-width: 660px;
  border-spacing: 0;
  border-collapse: collapse;
}
body .a-reviews-table .a-review-text {
  display: inline !important; float: none; background-color: transparent; color: #333333; cursor: text; font-family: 'Trebuchet MS',Arial,sans-serif; font-size: 13px; font-size-adjust: none; font-stretch: normal; font-style: normal; font-variant: normal; font-weight: 300; letter-spacing: normal; line-height: 21.12px; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;
}
body .a-reviews-table .a-reviews-link a {
  color: #006699; text-decoration: none;
}
body .a-reviews-table .a-reviews-link {
  font-size: 12px;
  background: #e5ecf9;
}
body .review-caption {
  max-width: 660px;
  background: none repeat scroll 0 0 #EAEAEA;
  border-radius: 5px 5px 5px 5px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: center;
  padding: 15px 15px 15px 15px;
}
body .review-caption p {
  color: #888;
  font-size: 12px;
  font-style: italic;
  margin: 0;
  padding: 0.9em 0;
}
/* end reviews page */

#footer-area {
  text-align: center;
  background: none repeat scroll 0 0 #E8EBF1;
  border-top: 1px solid #D9D9D9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #222222;
  font-size: 13px;
}

#top-nav-menu.top-nav .menu-btn {
  margin-left: auto;
  display: none;
}
#top-nav-menu.top-nav {
  box-shadow: none; 
  border: none;
}
@media (max-width: 767px){
  #top-nav-menu.top-nav .menu-btn {
    display: block;
  }
  #top-nav-menu.top-nav .nav-menu {
    display: none;
  }
  #top-nav-menu.top-nav.show-menu .nav-menu {
    display: block;
  }
}

#clarktrees .pre-text { white-space: pre; }
.pointer { cursor: pointer !important;}
.pointer label { cursor: pointer !important;}
.mt-0{margin-top:0!important;}.mr-0{margin-right:0!important;}.mb-0{margin-bottom:0!important;}.ml-3{margin-left:0!important;}
.mt-1{margin-top:.25rem!important;}.mr-1{margin-right:.25rem!important;}.mb-1{margin-bottom:.25rem!important;}.ml-1{margin-left:.25rem!important;}
.mt-2{margin-top:.5rem!important;}.mr-2{margin-right:.5rem!important;}.mb-2{margin-bottom:.5rem!important;}.ml-2 {margin-left:.5rem!important;}
.mt-3{margin-top:1rem!important;}.mr-3{margin-right:1rem!important;}.mb-3{margin-bottom:1rem!important;}.ml-3{margin-left:1rem!important;}
.mt-4{margin-top:1.5rem!important;}.mr-5{margin-right:1.5rem!important;}.mb-4{margin-bottom:1.5rem!important;}.ml-4{margin-left:1.5rem!important;}
.mt-5{margin-top:3rem!important;}.mr-5{margin-right:3rem!important;}.mb-5{margin-bottom:3rem!important;}.ml-5{margin-left:3rem!important;}
/*
fontSizeAdjust: 'none' font-stretch: normal; font-style: normal; font-variant: normal; font-weight: 300; letter-spacing: normal; line-height: 21.12px; orphans: 2; text-align: left; text-decoration: none; text-indent: 0px; text-transform: none; -webkit-text-stroke-width: 0px; white-space: normal; word-spacing: 0px;"
*/
/* @media (min-width: 992px){
  .container {
    max-width: 960px;
  }
}
@media (min-width: 768px){
  .container {
    max-width: 720px;
  }
}
@media (min-width: 576px){
  .container {
    max-width: 540px;
  }
}
@media (min-width: 1200px){
  .container {
    max-width: 1140px;
  }
} */






